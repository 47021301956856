@font-face {
	font-family: "MonserratBold";
	src: url("../../../../fonts/Monserrat/Montserrat\ Bold\ 700.ttf")
}
@font-face {
	font-family: "Monserrat";
	src: url("../../../../fonts/Monserrat/Montserrat\ Regular\ 400.ttf")
}
@font-face {
	font-family: "Shanti";
	src: url("../../../../fonts/Shanti-Regular.ttf")
}
@font-face {
	font-family: "Annabelle";
	src: url("../../../../fonts/Annabelle.ttf")
}
@font-face {
    font-family: 'annabelleregular';
    src: url('annabelle-webfont.woff2') format('woff2'),
         url('annabelle-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
	//font-weight: 700;
	font-family: "Shanti";
}

h1, h2,
.h1, .h2 {
	font-stretch: expanded;
	font-family: "MonserratBold";
}