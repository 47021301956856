[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

// Hero illustration
.illustration-section-01::after {
	@include illustration('illustration-section-01.svg', 100%, 286px, top);
}

// Features illustration 02
.illustration-section-02::after {
	@include illustration('illustration-section-02.svg', 100%, 253px, null, null, 30px);
}

html, body {
	padding: 0;
	margin: 0;
	// width: 100vw;
	// height: 100vh;
	position: relative;
	//overflow: hidden;
	// background: linear-gradient(#123, #111);
  }
  
  .snowflake {
	--size: 1vw;
	width: var(--size);
	height: var(--size);
	background: white;
	border-radius: 50%;
	position: absolute;
	top: -5vh;
  }
  
  @keyframes snowfall {
	0% {
	  transform: translate3d(var(--left-ini), 0, 0);
	}
	100% {
	  transform: translate3d(var(--left-end), 400vh, 0);
	}
  }
  
  @for $i from 1 through 50 {
	.snowflake:nth-child(#{$i}) {
	  --size: #{random(5) * 0.2}vw;
	  --left-ini: #{random(20) - 10}vw;
	  --left-end: #{random(20) - 10}vw;
	  left: #{random(100)}vw;
	  animation: snowfall #{5 + random(10)}s linear infinite;
	  animation-delay: -#{random(10)}s;
	}
  }
  
  /* added small blur every 6 snowflakes*/
  .snowflake:nth-child(6n) {
	filter: blur(1px);
  }
  
  
  
 
  
  
 
  
 
  